var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "userId", _vm._n($$v))
                  },
                  expression: "formData.userId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用名称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用名称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.logo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "logo", $$v)
                  },
                  expression: "formData.logo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "1--公众号 2--小程序 3--个号 4--APP 5--外部公众号 6--外部机器人 7--外部小程序 8--普通H5 99--推老板(老):"
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.type,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "type", $$v)
                  },
                  expression: "formData.type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "appKey字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.appKey,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "appKey", $$v)
                  },
                  expression: "formData.appKey"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "appSecret字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.appSecret,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "appSecret", $$v)
                  },
                  expression: "formData.appSecret"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "令牌(用户ID加密):" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.token,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "token", $$v)
                  },
                  expression: "formData.token"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "IV(用户ID加密):" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.alphabet,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "alphabet", $$v)
                  },
                  expression: "formData.alphabet"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "域名:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.host,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "host", $$v)
                  },
                  expression: "formData.host"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "status字段:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "status", $$v)
                  },
                  expression: "formData.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模式：1-自营；2-代理:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.mode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mode", _vm._n($$v))
                  },
                  expression: "formData.mode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信剩余条数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.smsCount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "smsCount", _vm._n($$v))
                  },
                  expression: "formData.smsCount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "饿了么PID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.elmPid,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "elmPid", $$v)
                  },
                  expression: "formData.elmPid"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信签名:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.smsSignature,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "smsSignature", $$v)
                  },
                  expression: "formData.smsSignature"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码登录模式临时字段用来判断app:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.spacialColumn,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "spacialColumn", _vm._n($$v))
                  },
                  expression: "formData.spacialColumn"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "domain字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.domain,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "domain", $$v)
                  },
                  expression: "formData.domain"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "subDomain字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.subDomain,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "subDomain", $$v)
                  },
                  expression: "formData.subDomain"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }